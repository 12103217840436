function setCookie(name, value, minutes) {
  const d = new Date();
  d.setTime(d.getTime() + minutes * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}

function getCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim();
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length);
  }
  return null;
}

document.addEventListener("turbo:load", initializePropertyPage);

function initializePropertyPage() {
  const form = document.getElementById("contact-form");
  const successMessage = document.getElementById("contact-success");
  const alreadySubmittedMessage = document.getElementById(
    "already-submitted-message"
  );

  if (form) {
    if (getCookie("formSubmitted")) {
      alreadySubmittedMessage.classList.remove("d-none");
      form.classList.add("d-none");
    } else {
      form.addEventListener("turbo:submit-end", (event) => {
        const detail = event.detail;

        if (detail.success) {
          setCookie("formSubmitted", "true", 10);
          if (typeof gtag === "function") {
            gtag("event", "submit", {
              category: "Contact Agent",
              access_point: "Property Page Tab",
            });
          }
          successMessage.classList.remove("d-none");
          form.classList.add("d-none");
        } else {
          alert("Your contact submission failed. Please try again later.");
        }
      });
    }
  }

  $("#scrollToRentTable").on("click", () => {
    const rentTableSection = $(".rent-info-list");
    if (rentTableSection.length) {
      $("html, body").animate(
        {
          scrollTop: rentTableSection.offset().top,
        },
        800
      );
    }
  });

  $(() => {
    $("[data-related-tab]").on("click", (event) => {
      event.preventDefault();

      const tabId = event.currentTarget.getAttribute("data-related-tab");
      const $tab = $(`[aria-controls="${tabId}"]`);

      if ($tab.length > 0) {
        $tab.trigger("click");
        $("body, html").animate({ scrollTop: $tab.offset().top - 20 }, 500);
      }

      /* analytics */
      if (
        event.currentTarget.id === "contact-agent-top" &&
        typeof gtag === "function"
      ) {
        gtag("event", "tracked_click", {
          category: "Contact Agent",
          access_point: "Property Page Top Button",
        });
      }

      if (
        event.currentTarget.id === "tour-yourself-top" &&
        typeof gtag === "function"
      ) {
        gtag("event", "tracked_click", {
          category: "Tour Yourself",
          access_point: "Property Page Top Button",
        });
      }
      /* analytics */
    });

    /* analytics */
    $("#agent-tab").on("click", () => {
      if (typeof gtag === "function") {
        gtag("event", "tracked_click", {
          category: "Contact Agent",
          access_point: "Property Page Tab",
        });
      }
    });

    $("#more-filters-btn").on("click", () => {
      if (typeof gtag === "function") {
        gtag("event", "tracked_click", {
          category: "More Filters",
          access_point: "Search Page",
        });
      }
    });

    $("#tour-yourself-tab").on("click", () => {
      if (typeof gtag === "function") {
        gtag("event", "tracked_click", {
          category: "Tour Yourself",
          access_point: "Property Page Tab",
        });
      }
    });
    // Analytics for click events on share buttons
    $("#twitter-share-button").on("click", () => {
      if (typeof gtag === "function") {
        gtag("event", "tracked_click", {
          category: "Social Share",
          access_point: "Property Page - Twitter",
        });
      }
    });

    $("#facebook-share-button").on("click", () => {
      if (typeof gtag === "function") {
        gtag("event", "tracked_click", {
          category: "Social Share",
          access_point: "Property Page - Facebook",
        });
      }
    });

    $("#pinterest-share-button").on("click", () => {
      if (typeof gtag === "function") {
        gtag("event", "tracked_click", {
          category: "Social Share",
          access_point: "Property Page - Pinterest",
        });
      }
    });

    $("#email-share-button").on("click", () => {
      if (typeof gtag === "function") {
        gtag("event", "tracked_click", {
          category: "Social Share",
          access_point: "Property Page - Email",
        });
      }
    });

    $(".c-property__apply").on("click", (event) => {
      event.preventDefault();
      if (typeof gtag === "function") {
        gtag("event", "tracked_click", {
          category: "Apply",
          access_point: "Property Page",
        });
      }
      window.location = event.currentTarget.href;
    });
    /* analytics */
  });
}

// forces a datepicker input format when the form is submitted
$("#search-form").on("submit", function (event) {
  let dateInput = event.target.querySelector("#available_date").value;
  $("#available_date").datepicker("update", dateInput);
});
$("#search-form-mobile").on("submit", function (event) {
  let dateInput = event.target.querySelector("#available_date").value;
  $("#available_date").datepicker("update", dateInput);
});
document.addEventListener("turbo:load", function () {
  const forms = document.querySelectorAll(".needs-validation");

  Array.prototype.slice.call(forms).forEach(function (form) {
    const commentsField = form.querySelector('textarea[name="comments"]');
    const emailField = form.querySelector('input[name="email"]');

    form.addEventListener(
      "submit",
      function (event) {
        if (
          commentsField &&
          commentsField.value.trim() !== "" &&
          !/^[a-zA-Z0-9\s.]*$/.test(commentsField.value)
        ) {
          commentsField.setCustomValidity(
            "Comments can contain only letters and numbers."
          );
        } else {
          commentsField.setCustomValidity("");
        }

        const emailPattern = /^[a-zA-Z0-9._%±]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/;
        if (emailField && !emailPattern.test(emailField.value)) {
          emailField.setCustomValidity("Please enter a valid email address.");
        } else {
          emailField.setCustomValidity("");
        }
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
        }

        form.classList.add("was-validated");
      },
      false
    );
  });
});
