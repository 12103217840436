async function initializeMapSearch() {
  let map;
  let currentInfoWindow = null;

  const mapSwitch = document.getElementById("filters-map-tab");
  const statePicker = document.getElementById("state-picker");
  const cityPicker = document.getElementById("city-picker");
  const mapContainer = document.getElementById("map-search");

  const statesLatLng = {
    AL: { lat: 32.361538, lng: -86.279118 },
    AK: { lat: 58.301935, lng: -134.41974 },
    AZ: { lat: 33.448457, lng: -112.073844 },
    AR: { lat: 34.736009, lng: -92.331122 },
    CA: { lat: 38.555605, lng: -121.468926 },
    CO: { lat: 39.7391667, lng: -104.984167 },
    CT: { lat: 41.767, lng: -72.677 },
    DE: { lat: 39.161921, lng: -75.526755 },
    FL: { lat: 30.4518, lng: -84.27277 },
    GA: { lat: 33.76, lng: -84.39 },
    HI: { lat: 21.30895, lng: -157.826182 },
    ID: { lat: 43.613739, lng: -116.237651 },
    IL: { lat: 39.78325, lng: -89.650373 },
    IN: { lat: 39.790942, lng: -86.147685 },
    IA: { lat: 41.590939, lng: -93.620866 },
    KS: { lat: 39.04, lng: -95.69 },
    KY: { lat: 38.197274, lng: -84.86311 },
    LA: { lat: 30.45809, lng: -91.140229 },
    ME: { lat: 44.323535, lng: -69.765261 },
    MD: { lat: 38.972945, lng: -76.501157 },
    MA: { lat: 42.2352, lng: -71.0275 },
    MI: { lat: 42.7335, lng: -84.5467 },
    MN: { lat: 44.95, lng: -93.094 },
    MS: { lat: 32.32, lng: -90.207 },
    MO: { lat: 38.572954, lng: -92.189283 },
    MT: { lat: 46.595805, lng: -112.027031 },
    NE: { lat: 40.809868, lng: -96.675345 },
    NV: { lat: 39.160949, lng: -119.753877 },
    NH: { lat: 43.220093, lng: -71.549127 },
    NJ: { lat: 40.221741, lng: -74.756138 },
    NM: { lat: 35.667231, lng: -105.964575 },
    NY: { lat: 42.659829, lng: -73.781339 },
    NC: { lat: 35.771, lng: -78.638 },
    ND: { lat: 48.813343, lng: -100.779004 },
    OH: { lat: 39.962245, lng: -83.000647 },
    OK: { lat: 35.482309, lng: -97.534994 },
    OR: { lat: 44.931109, lng: -123.029159 },
    PA: { lat: 40.269789, lng: -76.875613 },
    RI: { lat: 41.82355, lng: -71.422132 },
    SC: { lat: 34.0, lng: -81.035 },
    SD: { lat: 44.367966, lng: -100.336378 },
    TN: { lat: 36.165, lng: -86.784 },
    TX: { lat: 30.266667, lng: -97.75 },
    UT: { lat: 40.7547, lng: -111.892622 },
    VT: { lat: 44.26639, lng: -72.57194 },
    VA: { lat: 37.54, lng: -77.46 },
    WA: { lat: 47.042418, lng: -122.893077 },
    WV: { lat: 38.349497, lng: -81.633294 },
    WI: { lat: 43.074722, lng: -89.384444 },
    WY: { lat: 41.145548, lng: -104.802042 },
  };

  const getCenter = async () => {
    const point = { center: { lat: 39.0, lng: -100.0 }, zoom: 4 };
    if (statePicker === null || cityPicker === null) {
      return point;
    } else if (statePicker.value !== "" && cityPicker.value !== "") {
      const geocoder = new google.maps.Geocoder();
      return new Promise((resolve) => {
        geocoder.geocode(
          { address: `${cityPicker.value}, ${statePicker.value}` },
          (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              point.center = results[0].geometry.location;
              point.zoom = 10;
            }
            resolve(point);
          }
        );
      });
    } else if (
      statePicker.value !== "" &&
      statesLatLng[statePicker.value] !== undefined
    ) {
      point.center = statesLatLng[statePicker.value];
      point.zoom = 6;
    }
    return point;
  };

  const initMapSearch = async (options) => {
    if (mapContainer) {
      map = new google.maps.Map(mapContainer, options);
      google.maps.event.addListener(map, "idle", async () => {
        const bounds = map.getBounds().toJSON();
        if (bounds.west === bounds.east) {
          return;
        }
        try {
          const data = await $.get("/api/properties/bounds", {
            west: bounds.west,
            north: bounds.north,
            east: bounds.east,
            south: bounds.south,
          });

          const markers = data.map((property) => {
            const markerOptions = {
              position: new google.maps.LatLng(
                property.latitude,
                property.longitude
              ),
              map: map,
              title: property.heading,
              icon: "/map-marker.svg",
            };

            let marker = new google.maps.Marker(markerOptions);

            let rentDisplay;
            if (
              property.rentSchedules &&
              Object.keys(property.rentSchedules).length > 0
            ) {
              const rents = Object.values(property.rentSchedules).map(
                (schedule) => schedule.monthlyRent || schedule
              );
              const minRent = Math.min(...rents);
              const maxRent = Math.max(...rents);
              rentDisplay = `$${minRent} - $${maxRent}`;
            } else {
              rentDisplay = `$${property.rent}`;
            }

            const availableDate = property.available_date
              ? `${property.available_date.month}/${property.available_date.year}`
              : "N/A";
            const formattedArea =
              property.area > 0 ? property.area.toLocaleString("en-US") : "";

            const infowindow = new google.maps.InfoWindow({
              content: `
                <div class="custom-map-infobox" style="font-family: Arial, sans-serif; max-width: 260px; position: relative; padding: 0;">
                  <div class="d-none d-md-block m-2">
                    <div style="position: relative; margin-bottom: 10px;">
                      <img class="img-fluid rounded-1" src="${
                        property.images[0] || ""
                      }" style="width: 100%; max-width: 320px; max-height: 160px; display: block; margin: 0 auto; object-fit: cover;">
                    </div>
                    <div style="font-size: 16px; font-weight: bold; color: #007bff; text-align: center; padding: 0 5px; margin-bottom: 10px;">
                      <a href="/homes/${
                        property.id
                      }" style="text-decoration: none; color: #007bff;" tabindex="0">
                        ${property.heading}
                      </a>
                    </div>

                      <div class="text-center" style="font-size: 14px;"><strong>Rent:</strong> <span style="font-weight: bold;">${rentDisplay}</span></div>
                      <div class="text-center" style="font-size: 14px;"><strong>Available:</strong> <span style="font-weight: bold;">${availableDate}</span></div>

                    <div class="row d-flex align-content-center justify-content-center mb-3">
                      <div class="col-auto py-1 px-2">
                        <span class="text-extra-bold text-dark-grey">${
                          property.bedrooms
                        }</span><span class="text-muted"> beds</span>
                        <span class="separator text-small mx-1" aria-hidden="true"> ● </span>
                        <span class="text-extra-bold text-dark-grey">${
                          property.bathrooms
                        }</span><span class="text-muted"> baths</span>
                        <span class="separator mx-1" aria-hidden="true"> ● </span>
                        <span class="text-extra-bold text-dark-grey">${formattedArea}</span><span class="text-muted"> sqft</span>
                      </div>
                    </div>
                  </div>
                  <div class="d-block d-md-none m-2">
                    <div class="text-center mb-2" style="font-size: 13px; font-weight: bold;">
                      <a href="/homes/${
                        property.id
                      }" style="text-decoration: none; color: #007bff;" tabindex="0">
                        ${property.heading}
                      </a>
                    </div>
                    <div class="row g-0">
                      <div class="col-4">
                        <img class="img-fluid rounded-1" src="${
                          property.images[0] || ""
                        }" style="width: 100%; height: 80px; object-fit: cover;">
                      </div>
                      <div class="col-8 ps-2" style="font-size: 12px;">
                        <div><strong>Rent:</strong> <span style="font-weight: bold;">${rentDisplay}</span></div>
                        <div><strong>Available:</strong> <span style="font-weight: bold;">${availableDate}</span></div>
                        <div><span class="text-extra-bold text-dark-grey">${
                          property.bedrooms
                        }</span><span class="text-muted"> beds</span></div>
                        <div><span class="text-extra-bold text-dark-grey">${
                          property.bathrooms
                        }</span><span class="text-muted"> baths</span></div>
                        <div><span class="text-extra-bold text-dark-grey">${formattedArea}</span><span class="text-muted"> sqft</span></div>
                      </div>
                    </div>
                  </div>
                </div>

              `,
            });

            marker.addListener("click", () => {
              if (currentInfoWindow) {
                currentInfoWindow.close();
              }
              infowindow.open(map, marker);
              currentInfoWindow = infowindow;
            });

            return marker;
          });

          new MarkerClusterer(map, markers);
        } catch (error) {
          console.error("Error fetching property data:", error);
        }
      });
    }
  };

  const loadMap = async () => {
    const point = await getCenter();
    if (typeof map === "undefined") {
      initMapSearch(point);
    } else {
      map.setCenter(point.center);
      map.setZoom(point.zoom);
    }
  };

  document.addEventListener("turbo:load", loadMap);

  if (mapSwitch) {
    mapSwitch.addEventListener("click", loadMap);
  }
}

document.addEventListener("turbo:load", initializeMapSearch);
