document.addEventListener("turbo:load", function () {
  const searchInput = document.getElementById("header-search");
  const suggestionsList = document.getElementById("suggestions");
  const clearButton = document.getElementById("clear-search");
  let cities = [];
  let properties = [];
  let metros = [];
  let states = [];

  if (!searchInput || !suggestionsList || !clearButton) {
    return;
  }

  function toggleClearButton() {
    clearButton.style.display = searchInput.value.length > 0 ? "block" : "none";
  }

  searchInput.addEventListener("input", toggleClearButton);

  clearButton.addEventListener("click", function () {
    searchInput.value = "";
    toggleClearButton();
    searchInput.focus();
    suggestionsList.innerHTML = "";
  });

  toggleClearButton();

  fetch("/api/autosearch")
    .then((response) => response.json())
    .then((data) => {
      cities = data.cities;
      properties = data.properties;
      metros = data.metros;
      states = data.states;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });

  function createSuggestionItem(icon, name, url, query) {
    const suggestionItem = document.createElement("li");

    const iconDiv = document.createElement("div");
    iconDiv.classList.add("icon");
    iconDiv.innerHTML = icon;

    const textDiv = document.createElement("div");
    const highlightedName = name.replace(
      new RegExp(query, "gi"),
      (match) => `<span class="highlight">${match}</span>`
    );
    textDiv.innerHTML = highlightedName;
    textDiv.classList.add("text-content");

    suggestionItem.appendChild(iconDiv);
    suggestionItem.appendChild(textDiv);
    suggestionItem.classList.add("suggestion-item");
    suggestionItem.dataset.url = url;

    suggestionItem.addEventListener("click", function () {
      searchInput.value = name;
      suggestionsList.innerHTML = "";
      window.location.href = url;
    });

    return suggestionItem;
  }

  function createSuggestionHeader(text) {
    const header = document.createElement("li");
    header.textContent = text;
    header.classList.add("suggestion-header");
    return header;
  }

  searchInput.addEventListener("input", function () {
    const query = searchInput.value.toLowerCase();
    suggestionsList.innerHTML = "";

    if (query.length < 3) {
      return;
    }

    const filteredMetros = metros.filter((metro) =>
      metro.name?.toLowerCase().includes(query)
    );
    const filteredCities = cities.filter((city) =>
      city.name?.toLowerCase().includes(query)
    );
    const filteredProperties = properties.filter((property) =>
      property.name?.toLowerCase().includes(query)
    );
    const filteredStates = states.filter((state) =>
      state.name.toLowerCase().includes(query)
    );

    if (filteredStates.length > 0) {
      suggestionsList.appendChild(createSuggestionHeader("States"));
      filteredStates.forEach((state) => {
        suggestionsList.appendChild(
          createSuggestionItem(
            '<svg class="me-3" width="18" height="17" fill="#2f92d4"><use xlink:href="#svg-icon-state"></use></svg>',
            state.name,
            state.url,
            query
          )
        );
      });
    }

    if (filteredMetros.length > 0) {
      suggestionsList.appendChild(createSuggestionHeader("Metros"));
      filteredMetros.forEach((metro) => {
        suggestionsList.appendChild(
          createSuggestionItem(
            '<svg class="me-3" width="18" height="17" fill="#2f92d4"><use xlink:href="#svg-icon-metro"></use></svg>',
            metro.name,
            metro.url,
            query
          )
        );
      });
    }

    if (filteredCities.length > 0) {
      suggestionsList.appendChild(createSuggestionHeader("Cities"));
      filteredCities.forEach((city) => {
        suggestionsList.appendChild(
          createSuggestionItem(
            '<svg class="me-3" width="18" height="17" fill="#2f92d4"><use xlink:href="#svg-icon-city"></use></svg>',
            city.name,
            city.url,
            query
          )
        );
      });
    }

    if (filteredProperties.length > 0) {
      suggestionsList.appendChild(createSuggestionHeader("Properties"));
      filteredProperties.forEach((property) => {
        suggestionsList.appendChild(
          createSuggestionItem(
            '<svg class="me-3" width="18" height="17" fill="#2f92d4"><use xlink:href="#svg-icon-market"></use></svg>',
            property.name,
            property.url,
            query
          )
        );
      });
    }
  });
});
